import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import {} from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './supply.reducer';

export const SupplyDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const supplyEntity = useAppSelector(state => state.supply.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="supplyDetailsHeading">Supply</h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">ID</span>
          </dt>
          <dd>{supplyEntity.id}</dd>
          <dt>
            <span id="name">Name</span>
          </dt>
          <dd>{supplyEntity.name}</dd>
          <dt>
            <span id="isEnabled">Is Enabled</span>
          </dt>
          <dd>{supplyEntity.isEnabled ? 'true' : 'false'}</dd>
          <dt>
            <span id="supplyDomain">Supply Domain</span>
          </dt>
          <dd>{supplyEntity.supplyDomain}</dd>
          <dt>
            <span id="isThirdPartySupply">Is Third Party Supply</span>
          </dt>
          <dd>{supplyEntity.isThirdPartySupply ? 'true' : 'false'}</dd>
          <dt>
            <span id="type">Type</span>
          </dt>
          <dd>{supplyEntity.type}</dd>
          <dt>
            <span id="requestProcessingTime">Request Processing Time</span>
          </dt>
          <dd>{supplyEntity.requestProcessingTime}</dd>
          <dt>
            <span id="defaultSupplyTmax">Default Supply Tmax</span>
          </dt>
          <dd>{supplyEntity.defaultSupplyTmax}</dd>
          <dt>
            <span id="blockedCreativeAttributes">Blocked Creative Attributes</span>
          </dt>
          <dd>{supplyEntity.blockedCreativeAttributes}</dd>
          <dt>
            <span id="blockedCategories">Blocked Categories</span>
          </dt>
          <dd>{supplyEntity.blockedCategories}</dd>
          <dt>
            <span id="blockedBannerAdTypes">Blocked Banner Ad Types</span>
          </dt>
          <dd>{supplyEntity.blockedBannerAdTypes}</dd>
          <dt>
            <span id="datacenter">Datacenter</span>
          </dt>
          <dd>{supplyEntity.datacenter}</dd>
          <dt>
            <span id="supplyDescription">Supply Description</span>
          </dt>
          <dd>{supplyEntity.supplyDescription}</dd>
          <dt>
            <span id="isArchived">Is Archived</span>
          </dt>
          <dd>{supplyEntity.isArchived ? 'true' : 'false'}</dd>
          <dt>
            <span id="isMaxMindEnabled">Is Max Mind Enabled</span>
          </dt>
          <dd>{supplyEntity.isMaxMindEnabled ? 'true' : 'false'}</dd>
          <dt>
            <span id="isAuctionPriceMacroEnabled">Is Auction Price Macro Enabled</span>
          </dt>
          <dd>{supplyEntity.isAuctionPriceMacroEnabled ? 'true' : 'false'}</dd>
          <dt>
            <span id="isAdmTrackingEnabledForDisplay">Is Adm Tracking Enabled For Display</span>
          </dt>
          <dd>{supplyEntity.isAdmTrackingEnabledForDisplay ? 'true' : 'false'}</dd>
          <dt>User</dt>
          <dd>{supplyEntity.user ? supplyEntity.user.login : ''}</dd>
          <dt>Supply Partner</dt>
          <dd>{supplyEntity.supplyPartner ? supplyEntity.supplyPartner.supplyPartnerName : ''}</dd>
          <dt>Stodf</dt>
          <dd>
            {supplyEntity.stodfs
              ? supplyEntity.stodfs.map((val, i) => (
                  <span key={val.id}>
                    <a>{val.name}</a>
                    {supplyEntity.stodfs && i === supplyEntity.stodfs.length - 1 ? '' : ', '}
                  </span>
                ))
              : null}
          </dd>
        </dl>
        <Button tag={Link} to="/supply" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/supply/${supplyEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

export default SupplyDetail;
